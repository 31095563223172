import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getContractTypes({ commit }, data) {
      try {
        const response = await api.get(`/params/collaborators/contract-types`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCivilStates({ commit }, data) {
      try {
        const response = await api.get(`/params/collaborators/civil-states`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEsocialCategories({ commit }, data) {
      try {
        const response = await api.get(
          `/params/collaborators/e-social-categories`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
