import api from '@/services/api'

export default {
  state: {
    scheduling: {
      collaborator: null,
      contactMethod: null,
      typeOfExam: null,
      observation: null,
      typeOfSchedule: {
        code: null,
      },
      city: null,
      preferredRegion: null,
      unity: null,
      dateTime: null,
    },
  },

  mutations: {
    RESET_SCHEDULING(state) {
      state.scheduling = {
        collaborator: null,
        contactMethod: null,
        typeOfExam: null,
        observation: null,
        typeOfSchedule: {
          code: null,
        },
        city: null,
        preferredRegion: null,
        unity: null,
        dateTime: null,
      }
    },

    SET_COLLABORATOR(state, collaborator) {
      state.scheduling.collaborator = collaborator
    },

    SET_CONTACT(state, contactMethod) {
      state.scheduling.contactMethod = contactMethod
    },

    SET_TYPE_OF_EXAME(state, payload) {
      state.scheduling.typeOfExam = payload.typeOfExam
      state.scheduling.observation = payload.observation
    },

    SET_TYPE_OF_SCHEDULE(state, payload) {
      state.scheduling.typeOfSchedule = payload.typeOfSchedule
      state.scheduling.city = payload.city
      state.scheduling.preferredRegion = payload.preferredRegion
    },

    SET_DATETIME(state, payload) {
      state.scheduling.unity = payload.unity
      state.scheduling.dateTime = payload.dateTime
    },

    SET_DATETIME_SUGEST(state, payload) {
      state.scheduling.dateTime = payload
    },
  },

  actions: {
    async saveSchedule({ commit }, data) {
      try {
        return await api.post(`/schedules`, data)
      } catch (error) {
        throw error
      }
    },

    async getSchedules({ commit }, data) {
      try {
        const response = await api.get(
          `/schedules?startDate=${data.startDate}&endDate=${data.endDate}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSchedulesByCompany({ commit }, data) {
      try {
        const response = await api.get(
          `/schedules/company?companyCode=${data.companyCode}&startDate=${data.startDate}&endDate=${data.endDate}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSchedulesByCollaborator({ commit }, data) {
      try {
        const response = await api.get(
          `/schedules/collaborators?cpf=${data.cpf}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSchedule({ commit }, data) {
      try {
        const response = await api.get(`/schedules/${data.uuid}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateSchedule({ commit }, data) {
      try {
        return await api.put(`/schedules/${data.uuid}`, data.form)
      } catch (error) {
        throw error
      }
    },

    async selectSuggestDateSchedule({ commit }, data) {
      try {
        return await api.put(
          `/schedules/${data.uuid}/select-suggest-date-time`,
          data.form,
        )
      } catch (error) {
        throw error
      }
    },

    async selectDateSchedule({ commit }, data) {
      try {
        return await api.put(
          `/schedules/${data.uuid}/select-date-time`,
          data.form,
        )
      } catch (error) {
        throw error
      }
    },

    async finishSchedule({ commit }, data) {
      try {
        return await api.put(`/schedules/${data.uuid}/finish`, data)
      } catch (error) {
        throw error
      }
    },

    async cancelSchedule({ commit }, data) {
      try {
        return await api.put(`/schedules/${data.uuid}/cancel`, data)
      } catch (error) {
        throw error
      }
    },

    resetScheduling: ({ commit }) => {
      commit('RESET_SCHEDULING')
    },

    setCollaborator: ({ commit }, collaborator) => {
      commit('SET_COLLABORATOR', collaborator)
    },

    setContact: ({ commit }, contactMethod) => {
      commit('SET_CONTACT', contactMethod)
    },

    setTypeOfExam({ commit }, data) {
      commit('SET_TYPE_OF_EXAME', data)
    },

    setTypeOfSchedule({ commit }, data) {
      commit('SET_TYPE_OF_SCHEDULE', data)
    },

    setDateTime({ commit }, data) {
      commit('SET_DATETIME', data)
    },

    setDateTimeSugest({ commit }, data) {
      commit('SET_DATETIME_SUGEST', data)
    },
  },

  getters: {
    scheduling: (state) => state.scheduling,
  },
}
