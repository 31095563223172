<template>
  <div
    v-if="isManager || isAccreditation"
    class="navbar navbar-dark bg-blue navbar-expand-lg navbar-static border-bottom border-bottom-white border-opacity-10"
  >
    <div class="container-fluid ps-1">
      <div class="navbar-brand">
        <router-link
          :to="`/${baseURLProfile}/home`"
          class="d-inline-flex align-items-center"
          title="Home"
        >
          <img
            src="@/assets/img/logos/icone-04.svg"
            class="h-32px"
            alt="Logo do Ocupacional"
          />
        </router-link>
      </div>
      <div class="d-xl-none">
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-navigation"
          aria-expanded="true"
          title="Expandir/Recolher"
        >
          <i class="ph-list" />
        </button>
      </div>
      <div class="navbar-collapse collapse" id="navbar-navigation">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link
              :to="`/${baseURLProfile}/schedules`"
              class="navbar-nav-link rounded"
              title="Agenda"
            >
              <i class="ph ph-calendar me-2"></i>
              Agenda
            </router-link>
          </li>
          <li class="nav-item dropdown ms-xl-1">
            <a
              href="#"
              class="navbar-nav-link rounded dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ user.name }}
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <router-link
                v-if="isAccreditation"
                :to="`/${baseURLProfile}/syncs`"
                class="dropdown-item"
                title="Sincronizar Dados"
              >
                <i class="ph ph-database me-2" />
                Sincronizar Dados
              </router-link>
              <a
                class="dropdown-item cursor-pointer"
                @click="logout"
                title="Sair"
              >
                <i class="ph-sign-out me-2" />Sair
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PATHS from '@/router/routesMap'

export default {
  name: 'Navbar',

  computed: {
    ...mapGetters(['user', 'isManager', 'isAccreditation']),

    baseURLProfile() {
      if (this.isManager) {
        return 'managers'
      }
      if (this.isAccreditation) {
        return 'accreditations'
      }
      return ''
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.WELCOME)
      })
    },
  },
}
</script>
