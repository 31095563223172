import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Welcome from '../views/welcome/Welcome.vue'

const routes = [
  {
    name: 'Welcome',
    path: PATHS.WELCOME,
    component: Welcome,
  },
  {
    name: 'ManagerAuth',
    path: PATHS.MANAGER,
    component: () =>
      import(/* webpackChunkName: "manager" */ '../views/auth/ManagerAuth.vue'),
  },
  {
    name: 'ManagerHome',
    path: PATHS.MANAGER_HOME,
    component: () =>
      import(
        /* webpackChunkName: "manager" */ '../views/managers/home/Home.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'ManagerSchedules',
    path: PATHS.MANAGER_SCHEDULES,
    component: () =>
      import(
        /* webpackChunkName: "managerSchedules" */ '../views/managers/schedules/Calendar.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'ManagerSchedulesNew',
    path: PATHS.MANAGER_SCHEDULES_NEW,
    component: () =>
      import(
        /* webpackChunkName: "managerSchedules" */ '../views/managers/schedules/NewSchedule.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'ManagerSchedulesList',
    path: PATHS.MANAGER_SCHEDULES_LIST,
    component: () =>
      import(
        /* webpackChunkName: "managerSchedules" */ '../views/managers/schedules/ListSchedule.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'ManagerSchedulesDetails',
    path: PATHS.MANAGER_SCHEDULES_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "managerSchedules" */ '../views/managers/schedules/DetailsSchedule.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'ManagerCollaborators',
    path: PATHS.MANAGER_COLLABORATORS,
    component: () =>
      import(
        /* webpackChunkName: "managerCollaborators" */ '../views/managers/collaborators/ListCollaborators.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'ManagerCollaboratorNew',
    path: PATHS.MANAGER_COLLABORATORS_NEW,
    component: () =>
      import(
        /* webpackChunkName: "managerCollaborators" */ '../views/managers/collaborators/NewCollaborator.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'ManagerCollaboratorEdit',
    path: PATHS.MANAGER_COLLABORATORS_EDIT,
    component: () =>
      import(
        /* webpackChunkName: "managerCollaborators" */ '../views/managers/collaborators/EditCollaborator.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    name: 'CollaboratorAuth',
    path: PATHS.COLLABORATOR,
    component: () =>
      import(
        /* webpackChunkName: "collaborator" */ '../views/auth/CollaboratorAuth.vue'
      ),
  },
  {
    name: 'CollaboratorPreviewSchedules',
    path: PATHS.COLLABORATOR_PREVIEW_SCHEDULES,
    component: () =>
      import(
        /* webpackChunkName: "collaboratorPreview" */ '../views/collaborators/preview/schedules/ListSchedule.vue'
      ),
  },
  {
    name: 'CollaboratorSchedulesDetails',
    path: PATHS.COLLABORATOR_SCHEDULES_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "collaboratorSchedules" */ '../views/collaborators/schedules/DetailsSchedule.vue'
      ),
  },
  {
    name: 'AccreditationAuth',
    path: PATHS.ACCREDITATION,
    component: () =>
      import(
        /* webpackChunkName: "accreditation" */ '../views/auth/AccreditationAuth.vue'
      ),
  },
  {
    name: 'AccreditationHome',
    path: PATHS.ACCREDITATION_HOME,
    component: () =>
      import(
        /* webpackChunkName: "accreditation" */ '../views/accreditations/home/Home.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAccreditation: true,
    },
  },
  {
    name: 'AccreditationSchedules',
    path: PATHS.ACCREDITATION_SCHEDULES,
    component: () =>
      import(
        /* webpackChunkName: "accreditationSchedules" */ '../views/accreditations/schedules/Calendar.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAccreditation: true,
    },
  },
  {
    name: 'AccreditationSchedulesList',
    path: PATHS.ACCREDITATION_SCHEDULES_LIST,
    component: () =>
      import(
        /* webpackChunkName: "accreditationSchedules" */ '../views/accreditations/schedules/ListSchedule.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAccreditation: true,
    },
  },
  {
    name: 'AccreditationSchedulesDetails',
    path: PATHS.ACCREDITATION_SCHEDULES_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "accreditationSchedules" */ '../views/accreditations/schedules/DetailsSchedule.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAccreditation: true,
    },
  },
  {
    name: 'AccreditationSyncs',
    path: PATHS.ACCREDITATION_SYNCS,
    component: () =>
      import(
        /* webpackChunkName: "accreditationSyncs" */ '../views/accreditations/syncs/Syncs.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAccreditation: true,
    },
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  const { isLoggedInOcupacional, isManager, isAccreditation } = store.getters

  if (isLoggedInOcupacional && isManager && to.name === 'ManagerAuth') {
    return { name: 'ManagerHome' }
  }

  if (
    isLoggedInOcupacional &&
    isAccreditation &&
    to.name === 'AccreditationAuth'
  ) {
    return { name: 'AccreditationHome' }
  }

  if (to.meta.requiresAuth) {
    if (!isLoggedInOcupacional && !localStorage.getItem('tokenOcupacional')) {
      return { name: 'Welcome' }
    }
    if (to.meta.requiresManager && !isManager) {
      return { name: 'ManagerAuth' }
    }
    if (to.meta.requiresAccreditation && !isAccreditation) {
      return { name: 'AccreditationAuth' }
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedInOcupacional && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.WELCOME)
  }
})

export default router
