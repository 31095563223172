import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getTypesOfExam({ commit }, data) {
      try {
        const response = await api.get(`/params/schedules/types-of-exams`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getExams({ commit }, data) {
      try {
        const response = await api.get(
          `/params/schedules/exams?collaboratorCode=${data.collaboratorCode}&typeOfExam=${data.typeOfExam}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTypesOfSchedules({ commit }, data) {
      try {
        const response = await api.get(`/params/schedules/types-of-schedules`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getExamsByCollaborator({ commit }, data) {
      try {
        const response = await api.get(
          `/params/schedules/exams?companyCode=${data.companyCode}&collaboratorCode=${data.collaboratorCode}&typeOfExam=${data.typeOfExam}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getAvaliableTimes({ commit }, data) {
      try {
        const response = await api.get(
          `/params/schedules/available-times?companyCode=${data.companyCode}&codigoAgenda=${data.unityCode}&dataInicio=${data.startDate}&dataFim=${data.endDate}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCities({ commit }, data) {
      try {
        const response = await api.get(`/params/schedules/cities`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getServicesProviders({ commit }, data) {
      try {
        const response = await api.get(`/params/schedules/service-providers`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
