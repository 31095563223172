<template>
  <Navbar v-if="showComponent" />
  <div
    class="page-content"
    :style="
      showBackground
        ? {
            backgroundImage:
              'url(' + require(`@/assets/img/backgrounds/${background}`) + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }
        : {}
    "
  >
    <div class="content-wrapper">
      <div class="content-inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/commons/globals/Navbar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Layout',

  components: {
    Navbar,
  },

  data() {
    return {
      routesNotShowNavbar: [
        'Welcome',
        'ManagerAuth',
        'CollaboratorAuth',
        'CollaboratorPreviewSchedules',
        'CollaboratorSchedulesDetails',
        'AccreditationAuth',
        'AccreditationCalendarDetails',
        'Unauthorized',
        'NotFound',
      ],
      routesShowBackground: [
        'Welcome',
        'ManagerAuth',
        'CollaboratorAuth',
        'AccreditationAuth',
      ],
    }
  },

  computed: {
    ...mapGetters(['background']),

    showBackground() {
      return this.routesShowBackground.includes(this.$route.name)
    },

    showComponent() {
      return !this.routesNotShowNavbar.includes(this.$route.name)
    },
  },
}
</script>
