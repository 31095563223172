import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getScheduleLogs({ commit }, uuid) {
      try {
        const response = await api.get(`/schedules/${uuid}/logs`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
