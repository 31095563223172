export default {
  state: {
    background: 'background-01.jpg',
  },

  mutations: {
    SET_BACKGROUND(state, background) {
      state.background = background
    },
  },

  actions: {
    setBackground({ commit }, background) {
      commit('SET_BACKGROUND', background)
    },
  },

  getters: {
    background: (state) => state.background,
  },
}
