import api from '@/services/api'
import profileEnum from '@/enum/profileEnum'

export default {
  state: {
    isLoggedInOcupacional: !!localStorage.getItem('tokenOcupacional'),
    userOcupacional: {
      id: 0,
      name: '',
      profile: '',
      companyCode: '',
    },
  },

  mutations: {
    AUTH(state, user) {
      state.isLoggedInOcupacional = true
      state.userOcupacional = {
        id: user.id,
        name: user.name,
        profile: user.profile,
        companyCode: user.companyCode,
      }
    },

    LOGOUT(state) {
      state.isLoggedInOcupacional = false
      state.userOcupacional = {
        id: 0,
        name: '',
        profile: '',
        companyCode: '',
      }
    },
  },

  actions: {
    async authManager({ commit }, credentials) {
      try {
        const response = await api.post('/auth/managers', {
          cnpj: credentials.cnpj,
          code: credentials.code,
        })

        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''

        const user = {
          id: data && data.user ? data.user.id : 0,
          name: data && data.user ? data.user.name : '',
          profile: profileEnum.MANAGER,
          companyCode: data && data.user ? data.user.companyCode : '',
        }

        localStorage.setItem('tokenOcupacional', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },

    async authAccreditation({ commit }, credentials) {
      try {
        const response = await api.post('/auth/accreditations', {
          user: credentials.user,
          password: credentials.password,
        })

        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''

        const user = {
          id: data && data.user ? data.user.id : 0,
          name: data && data.user ? data.user.name : '',
          profile: profileEnum.ACCREDITATION,
          companyCode: data && data.user ? data.user.companyCode : '',
        }

        localStorage.setItem('tokenOcupacional', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },

    logout: ({ commit }) => {
      commit('LOGOUT')
    },
  },

  getters: {
    isLoggedInOcupacional: (state) => state.isLoggedInOcupacional,
    user: (state) => state.userOcupacional,
    companyCode: (state) => state.userOcupacional.companyCode,
    isManager: (state) => state.userOcupacional.profile === profileEnum.MANAGER,
    isAccreditation: (state) =>
      state.userOcupacional.profile === profileEnum.ACCREDITATION,
  },
}
