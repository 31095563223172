import commons from './modules/commons/commons'
import auth from './modules/auth/auth'
import unities from './modules/params/unities'
import departments from './modules/params/departments'
import positions from './modules/params/positions'
import paramsCollaborators from './modules/params/paramsCollaborators'
import paramsSchedules from './modules/params/paramsSchedules'
import collaborators from './modules/collaborators/collaborators'
import schedules from './modules/schedules/schedules'
import scheduleLogs from './modules/schedules/scheduleLogs'
import syncs from './modules/syncs/syncs'

export default {
  commons,
  auth,
  unities,
  departments,
  positions,
  paramsCollaborators,
  paramsSchedules,
  collaborators,
  schedules,
  scheduleLogs,
  syncs,
}
