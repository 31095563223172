import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveCollaborator({ commit }, data) {
      try {
        return await api.post(`/collaborators`, data)
      } catch (error) {
        throw error
      }
    },

    async getCollaborators({ commit }, data) {
      try {
        const response = await api.get(
          `/collaborators?${new URLSearchParams(data).toString()}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateCollaborator({ commit }, data) {
      try {
        return await api.put(`/collaborators`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
