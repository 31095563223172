const BASE_URL = '/'
const WELCOME = BASE_URL
const MANAGER = `${BASE_URL}managers`
const MANAGER_HOME = `${MANAGER}/home`
const MANAGER_SCHEDULES = `${MANAGER}/schedules`
const MANAGER_SCHEDULES_NEW = `${MANAGER_SCHEDULES}/new`
const MANAGER_SCHEDULES_LIST = `${MANAGER_SCHEDULES}/list`
const MANAGER_SCHEDULES_DETAILS = `${MANAGER_SCHEDULES}/details/:uuid`
const MANAGER_COLLABORATORS = `${MANAGER}/collaborators`
const MANAGER_COLLABORATORS_NEW = `${MANAGER_COLLABORATORS}/new`
const MANAGER_COLLABORATORS_EDIT = `${MANAGER_COLLABORATORS}/:cpf`
const COLLABORATOR = `${BASE_URL}collaborators`
const COLLABORATOR_PREVIEW = `${COLLABORATOR}/preview`
const COLLABORATOR_PREVIEW_SCHEDULES = `${COLLABORATOR_PREVIEW}/:cpf`
const COLLABORATOR_SCHEDULES = `${COLLABORATOR}/schedules`
const COLLABORATOR_SCHEDULES_DETAILS = `${COLLABORATOR_SCHEDULES}/details/:key`
const ACCREDITATION = `${BASE_URL}accreditations`
const ACCREDITATION_HOME = `${ACCREDITATION}/home`
const ACCREDITATION_SCHEDULES = `${ACCREDITATION}/schedules`
const ACCREDITATION_SCHEDULES_LIST = `${ACCREDITATION_SCHEDULES}/list`
const ACCREDITATION_SCHEDULES_DETAILS = `${ACCREDITATION_SCHEDULES}/details/:uuid`
const ACCREDITATION_SYNCS = `${ACCREDITATION}/syncs`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  WELCOME,
  MANAGER,
  MANAGER_HOME,
  MANAGER_SCHEDULES,
  MANAGER_SCHEDULES_NEW,
  MANAGER_SCHEDULES_LIST,
  MANAGER_SCHEDULES_DETAILS,
  MANAGER_COLLABORATORS,
  MANAGER_COLLABORATORS_NEW,
  MANAGER_COLLABORATORS_EDIT,
  COLLABORATOR,
  COLLABORATOR_PREVIEW_SCHEDULES,
  COLLABORATOR_SCHEDULES_DETAILS,
  ACCREDITATION,
  ACCREDITATION_HOME,
  ACCREDITATION_SCHEDULES,
  ACCREDITATION_SCHEDULES_LIST,
  ACCREDITATION_SCHEDULES_DETAILS,
  ACCREDITATION_SYNCS,
  UNAUTHORIZED,
}
