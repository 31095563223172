import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getDepartments({ commit }, data) {
      try {
        const response = await api.get(
          `/params/companies/${data.companyCode}/unities/${data.unityCode}/departments`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
