import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async syncCompanies({ commit }) {
      try {
        return await api.get(`/syncs/companies`)
      } catch (error) {
        throw error
      }
    },

    async syncUnities({ commit }) {
      try {
        return await api.get(`/syncs/unities`)
      } catch (error) {
        throw error
      }
    },

    async syncDepartments({ commit }) {
      try {
        return await api.get(`/syncs/departments`)
      } catch (error) {
        throw error
      }
    },

    async syncPositions({ commit }) {
      try {
        return await api.get(`/syncs/positions`)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
